<!--联系我们-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col >
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col >
        <el-row class="contentDiv">
          <div class="w-e-text-container">
            <div v-html="content" data-slate-editor></div>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <!--附件-->
    <el-row v-show="fileList && fileList.length > 0" class="outerRow">
      <el-col class="label_contain">
        <div class="fileLabelDiv">附件：</div>
        <div>
          <div v-for="(item, index) in fileList" :key="index" class="fileDiv">
            <span class="fileSpanDiv cursorPointer" @click="downloadFile(item)">{{item.fileName}}</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import { downLoadFile } from '@/utils/commonApi'

export default {
  name: 'contactUs',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '联系我们',
          path: '/giEdifice/contactUs'
        },
      ],
      content: '',
      fileList: [], //附件
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/contact/detail",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let obj = res.data.data;
          this.content = obj.information

          if (obj.file) {
            let contentFileId = obj.file//附件id
            let param = {
              fileIds: contentFileId
            }
            axios({
              method: "get",
              url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/uploadFilesQuery',
              params: param
            }).then(res => {
              if (res.data.code === 10000 || res.data.code === 200) {
                this.fileList = res.data.data
              } else {
                this.$message.warning("附件请求异常")
              }
            }).catch(err => {
              this.$message.error('附件请求失败')
            })
          }

        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },

    //附件下载
    downloadFile(file) {
      let fileInfo = {
        fileId: file.id,
        fileName: file.fileName
      }
      downLoadFile(fileInfo)
    }

  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.contentDiv {
  min-height: 517px;
  background: white;
  margin-bottom: 20px;
  padding: 25px;
}
.label_contain {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.fileLabelDiv {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  color: #3E3A39;
  line-height: 24px;
  align-self: flex-start;
}
.fileDiv {
  text-align: left;
  .fileSpanDiv {
    padding: 5px 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 28px;
  }
}
</style>
